import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { resetUser, setUser } from '../../redux/userSlice/userSlice';

const superAdminApi = createApi({
  reducerPath: 'superAdminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/super-admin/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    registerSuperAdmin: builder.mutation({
      query: (newAdmin) => ({
        url: 'register',
        method: 'POST',
        body: newAdmin,
      }),
    }),
    loginSuperAdmin: builder.mutation({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
        responseHandler: (response) => response.text(),
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem('token', data);
          dispatch(setUser({ token: data }));
        } catch (error) {
          console.error('Error logging in:', error);
        }
      },
    }),
    getAllSuperAdmins: builder.query({
      query: () => 'getAllSuperAdmins',
    }),
    logoutSuperAdmin: builder.mutation({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
      onQueryStarted: async (_: void, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          localStorage.removeItem('token');
          dispatch(resetUser());
        } catch (error) {
          console.error('Error logging out:', error);
        }
      },
    }),
  }),
});

export const {
  useRegisterSuperAdminMutation,
  useLoginSuperAdminMutation,
  useGetAllSuperAdminsQuery,
  useLogoutSuperAdminMutation,
} = superAdminApi;
export default superAdminApi;
