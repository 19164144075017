import { Link } from 'react-router-dom';
import { useGetAllDealersQuery } from '../../apis/dealershipApis/dealershipApis';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Button,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import {
  useGetAllSalesPersonsQuery,
  useRemoveSalesPersonFromDealershipMutation,
  useUpdateSalesPersonDealershipLinkMutation,
} from '../../apis/salesPersonsApis/salesPersonsApis';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function DealersTable() {
  const { data: dealers, isLoading: isDealersLoading } =
    useGetAllDealersQuery();
  // const { data: salesPersons, isLoading: isSalesPersonsLoading } = useGetAllSalesPersonsQuery();
  const [updateSalesPersonDealershipLink] = useUpdateSalesPersonDealershipLinkMutation();
  const [removeSalesPersonFromDealership] = useRemoveSalesPersonFromDealershipMutation();
  const isMobile = useMediaQuery('(max-width: 800px)');

  const [updatedDealers, setUpdatedDealers] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [selectedDealershipId, setSelectedDealershipId] = useState(null);
  const [selectedSalesPersons, setSelectedSalesPersons] = useState<any>([]);

  useEffect(() => {
    setUpdatedDealers(dealers);
    console.log('Dealers data:', dealers);
  }, [dealers]);

  const handleOpen = (dealershipId: any) => {
    const currentDealer = updatedDealers.find(
      (dealer: any) => dealer.id === dealershipId,
    );
    const currentSalesPersons =
      currentDealer.salesPersons?.map((sp: { id: any }) => sp.id) || [];
    setSelectedDealershipId(dealershipId);
    setSelectedSalesPersons(currentSalesPersons);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleSalesPerson = (salesPersonId: any) => {
    setSelectedSalesPersons((prev: any[]) => {
      if (prev.includes(salesPersonId)) {
        return prev.filter((id: any) => id !== salesPersonId);
      } else {
        return [...prev, salesPersonId];
      }
    });
  };

  // const handleSave = async () => {
  //   try {
  //     const currentDealer = updatedDealers.find((dealer: any) => dealer.id === selectedDealershipId);
  //     const currentSalesPersons = currentDealer.salesPersons?.map((sp: { id: any; }) => sp.id) || [];

  //     // Identify removed sales persons
  //     const removedSalesPersons = currentSalesPersons.filter((id: any) => !selectedSalesPersons.includes(id));

  //     // Call delete API for each removed sales person
  //     await Promise.all(removedSalesPersons.map(async (salesPersonId: string) => {
  //       await removeSalesPersonFromDealership({ salesPersonId, dealershipId: selectedDealershipId }).unwrap();
  //     }));
      
  //     const response = await updateSalesPersonDealershipLink({
  //       dealershipId: selectedDealershipId,
  //       salesPersonIds: selectedSalesPersons,
  //     }).unwrap();

  //     console.log(response.message);
  //     setUpdatedDealers((current: any[]) =>
  //       current.map((dealer: any) => {
  //         if (dealer.id === selectedDealershipId) {
  //           return {
  //             ...dealer,
  //             salesPersons: salesPersons?.filter((sp) =>
  //               selectedSalesPersons.includes(sp.id),
  //             ),
  //           };
  //         }
  //         return dealer;
  //       }),
  //     );

  //     handleClose();
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  if (isDealersLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ width: '100%', overflowY: 'hidden' }}>
        <Typography variant="h4" align="center" sx={{ my: 3, fontWeight: 700 }}>
          All Dealers
        </Typography>
        {isMobile ? (
          <Box sx={{ padding: 2 }}>
            {updatedDealers?.map((dealer: any) => (
              <Card key={dealer.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <strong>Name:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Link
                        to={`/dealership/${dealer.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Typography
                          sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                        >
                          {dealer.name}
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Email:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                      >
                        {dealer.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Phone:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                      >
                        {dealer.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Address:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                      >
                        {dealer.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>AI Name:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                      >
                        {dealer.aiName}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>Sales Persons:</strong>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
                      >
                        {dealer.salesPersons
                          .map((sp: any) => sp.name)
                          .join(', ')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <IconButton onClick={() => handleOpen(dealer.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <TableContainer sx={{ margin: 'auto', maxHeight: 575 }}>
            <Table stickyHeader aria-label="dealers table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Email
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Phone
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Address
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                    AI Name
                  </TableCell>
                  {/* <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Sales Persons
                  </TableCell> */}
                  {/* <TableCell align="center" sx={{ fontWeight: 600 }}>
                    Actions
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {updatedDealers?.map((dealer: any) => (
                  <TableRow key={dealer.id}>
                    <TableCell align="center">
                      <Link
                        to={`/dealership/${dealer.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {dealer.name}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {dealer.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {dealer.phone}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {dealer.address}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {dealer.aiName}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center">
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {dealer?.salesPersons
                          ?.map((sp: any) => sp.name)
                          .join(', ') || ''}
                      </Typography>
                    </TableCell> */}
                    {/* <TableCell align="center">
                      <IconButton onClick={() => handleOpen(dealer.id)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      {/* <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: 700 }}
            gutterBottom
          >
            Select Sales Persons
          </Typography>
          <List>
            {salesPersons?.map((salesPerson) => (
              <ListItem key={salesPerson.id} disablePadding>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedSalesPersons.includes(salesPerson.id)}
                      onChange={() => handleToggleSalesPerson(salesPerson.id)}
                      color="secondary"
                    />
                  }
                  label={salesPerson.name}
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              onClick={handleSave}
              variant="contained"
              sx={{
                width: '10vh',
                background: '#4c00b0',
                '&:hover': {
                  backgroundColor: '#4c00b0',
                },
              }}
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              sx={{ ml: 2, width: '10vh' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal> */}
    </ThemeProvider>
  );
}

export default DealersTable;
