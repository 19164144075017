import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { HeaderDrawer } from '../../components/header/headerDrawer';
import {
  ThemeProvider,
  Toolbar,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import CustomerSupportTable from '../../components/customerSupportTable/CustomerSupportTable';
import { useGetAllTicketsQuery } from '../../apis/zendeskApis/zendeskApi';
import { useEffect } from 'react';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#4c00b0',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
        },
      },
    },
  },
});

export const CustomerSupport = () => {
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');

  const { data, refetch, isLoading } =
    useGetAllTicketsQuery();

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HeaderDrawer />
      </ThemeProvider>
      <Box component="main" sx={{ flexGrow: 1, p: isMobileOrTablet ? 2 : 5 }}>
        <Toolbar />
        <CustomerSupportTable data={data} refetch={refetch} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
