import { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  InputAdornment,
  Alert,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import { useGenerateSignupLinkMutation } from '../../apis/dealershipApis/dealershipApis';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

const AddDealerModal = ({ handleAlert, handleAddDealer }: any) => {
  const [generateSignupLink] = useGenerateSignupLinkMutation();
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [basePayment, setBasePayment] = useState('');
  const [error, setError] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSendInvite = () => {
    if (!email || ! basePayment) {
      setError('Email and base payment cannot be empty.')
    } else {
      generateSignupLink({ email, basePayment })
      .unwrap()
      .then((response) => {
        console.log('Sign-up link generated successfully:', response.data.link);
        handleAlert(`Email sent to ${email}`, 'success');
        handleAddDealer();
        setEmail('');
        setBasePayment('');
        handleClose();
      })
      .catch((error) => {
        console.error('Failed to generate sign-up link:', error);
        handleAlert(error.data.message, 'error');
        setEmail('');
        setBasePayment('');
        handleClose();
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          pt: isMobileOrTablet ? 1 : 3,
          pb: isMobileOrTablet ? 2 : 0,
        }}
      >
        <Button
          sx={{
            background: '#4c00b0',
            '&:hover': {
              backgroundColor: '#4c00b0',
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: isMobileOrTablet ? '12px' : '14px',
              inlineSize: isMobileOrTablet ? '80px' : 'none',
            }}
          >
            Add New Dealer
          </Typography>
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobileOrTablet ? '90%' : 500,
              maxWidth: '90%',
              height: 'auto',
              borderRadius: 2,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto', // To handle overflow content
            }}
          >
            <Typography
              sx={{ mb: 2, fontWeight: 700 }}
              variant={isMobileOrTablet ? 'h6' : 'h5'}
              component="h2"
            >
              Add New Dealer
            </Typography>
            <Box>
              {error && (
                <Alert
                  severity="error"
                  sx={{
                    fontFamily: APP_FONT,
                    fontWeight: 600,
                    fontSize: 16,
                    mb: 2,
                  }}
                >
                  {error}
                </Alert>
              )}
              <TextField
                margin="normal"
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Base Payment"
                type="number"
                value={basePayment}
                onChange={(e) => setBasePayment(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <Button
                sx={{
                  background: '#4c00b0',
                  mt: 2,
                  borderRadius: 5,
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    backgroundColor: '#4c00b0',
                  },
                }}
                variant="contained"
                onClick={handleSendInvite}
              >
                <Typography
                  sx={{
                    fontSize: isMobileOrTablet ? 12 : 16,
                    fontWeight: '600',
                    textTransform: 'none',
                  }}
                >
                  Send Invite
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default AddDealerModal;
