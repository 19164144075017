import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface Dealership {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  country: string;
  aiName: string;
}

export const signupLinksApi = createApi({
  reducerPath: 'signupLinksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/signup-links/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    generateSignupLink: builder.mutation({
      query: (newDealerData) => ({
        url: 'generate',
        method: 'POST',
        body: newDealerData,
      }),
    }),
    getAllDealersWithStatus: builder.query<any, string>({
      query: (status: string) => `/${status}`,
    })
  }),
});

export const DealersApi = createApi({
  reducerPath: 'getAllDealersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/super-admin/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllDealers: builder.query<Dealership[], void>({
      query: () => 'allDealers',
    }),
    getDealer: builder.query<any, string>({
      query: (id: string) => `/dealers/${id}`
    }),
  }),
});

export const { useGenerateSignupLinkMutation, useGetAllDealersWithStatusQuery } = signupLinksApi;
export const { useGetAllDealersQuery, useGetDealerQuery } = DealersApi;

