import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Dashboard } from './pages/dashboard/dashboard';
import Login from './pages/authentication/login';
import { Revenue } from './pages/revenue/revenue';
import ProtectedRoute from './utils/protectedRoute';
import { OnboardDealers } from './pages/onboard-dealers/OnboardDealers';
import { DealershipDetail } from './pages/dealership-detail/dealershipDetail';
import { CustomerSupport } from './pages/customer_support/CustomerSupport';
// import { SalesPersons } from './pages/sales-persons/sales-persons';
// import { SalesComission } from './pages/sales-comission/sales-comission';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route path="/" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onboard-dealers"
          element={
            <ProtectedRoute>
              <OnboardDealers />
            </ProtectedRoute>
          }
        />

        <Route
          path="/customer-support"
          element={
            <ProtectedRoute>
              <CustomerSupport />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dealership/:id"
          element={
            <ProtectedRoute>
              <DealershipDetail />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/sales-persons"
          element={
            <ProtectedRoute>
              <SalesPersons />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sales-comission"
          element={
            <ProtectedRoute>
              <SalesComission />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
