import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { HeaderDrawer } from '../../components/header/headerDrawer';
import {
  Alert,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  createTheme,
  ThemeProvider,
  CircularProgress,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import { useState } from 'react';
import { useGetDealerQuery } from '../../apis/dealershipApis/dealershipApis';
import { useParams } from 'react-router-dom';
import { useGetAllPaymentsQuery } from '../../apis/paymentApis/paymentApis';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#4c00b0',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
        },
      },
    },
  },
});

export const DealershipDetail = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const { id } = useParams(); // Fetch ID from URL
  const { data, refetch, isLoading } = useGetDealerQuery(id || ''); // Fetch data using ID
  const { data: payments, isLoading: paymentsLoading } = useGetAllPaymentsQuery(
    id || '',
  );

  const isMobileOrTablet = useMediaQuery('(max-width:900px)');
  const hasPayments = payments?.data && payments.data.length > 0;
  const isHeading = useMediaQuery('(max-width: 800px)');
  const formatAmount = (amount: any) => (amount / 100).toFixed(2);
  const formatDate = (timestamp: any) =>
    new Date(timestamp * 1000).toLocaleDateString();

  if (isLoading || paymentsLoading)
    return (
      <>
        <ThemeProvider theme={theme}>
          <HeaderDrawer />
        </ThemeProvider>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '110vh',
            marginLeft: '15%',
          }}
        >
          <CircularProgress />
        </div>
      </>
    );

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderDrawer />
        <Box component="main" sx={{ flexGrow: 1, p: 5 }}>
          <Toolbar />
          {alertMessage && (
            <Alert
              severity="success"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: 600,
                fontSize: 16,
                top: 20,
                right: 20,
              }}
            >
              {alertMessage}
            </Alert>
          )}
          {errorAlert && (
            <Alert
              severity="error"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: 600,
                fontSize: 16,
                top: 20,
                right: 20,
              }}
            >
              {errorAlert}
            </Alert>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{
                marginBottom: '2%',
                fontFamily: APP_FONT,
                fontWeight: '600',
                fontSize: isHeading ? '36px' : '48px',
                color: '#003650',
              }}
            >
              Dealership Details
            </Typography>
          </Box>

          <Paper elevation={3} sx={{ width: '100%', overflowY: 'hidden' }}>
            <TableContainer sx={{ margin: 'auto', maxHeight: 600 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Dealership Name</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.companyName || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership Address</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.address || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership Email</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.email || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership CRM Email</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                        >
                        {data.CrmDataEmail? data.CrmDataEmail : 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership Phone</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.phone || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership AI Phone</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.aiPhoneNumber || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Dealership Operating Hours</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {`${
                          data.workSchedule.startTime || 'Not provided!'
                        } to ${data.workSchedule.endTime || 'Not provided!'}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lead Assignment</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.leadsAssignment || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Voice</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.aiVoice || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AI Name</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.aiName || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AI Greeting</TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          wordBreak: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        {data.aiGreeting || 'Not provided!'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Box sx={{ marginTop: 5 }}>
            <Typography
              sx={{
                marginBottom: '2%',
                fontFamily: APP_FONT,
                fontWeight: '600',
                fontSize: isHeading ? '28px' : '36px',
                color: '#003650',
              }}
            >
              Dealership Payments
            </Typography>
            <Paper elevation={3} sx={{ width: '100%', overflowY: 'hidden' }}>
              {isMobileOrTablet ? (
                hasPayments ? (
                  <Box sx={{ padding: 2 }}>
                    {payments?.data.map((payment: any) => (
                      <Card key={payment.id} sx={{ mb: 2 }}>
                        <CardContent>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <strong>Payment ID:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {payment.id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Amount ($):</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {formatAmount(payment.amount)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Created:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {formatDate(payment.created)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Description:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {payment.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Status:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {payment.status}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Receipt:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                <a
                                  href={payment.receipt_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Receipt
                                </a>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="h6" align="center" sx={{ my: 3 }}>
                    No payment details available.
                  </Typography>
                )
              ) : (
                <TableContainer sx={{ margin: 'auto', maxHeight: 575 }}>
                  <Table stickyHeader aria-label="payments table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>
                          Payment ID
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>
                          Amount ($)
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Created</TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>
                          Description
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                        <TableCell sx={{ fontWeight: 600 }}>Receipt</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments?.data.map((payment: any) => (
                        <TableRow key={payment.id}>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {payment.id}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {formatAmount(payment.amount)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {formatDate(payment.created)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {payment.description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {payment.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              <a
                                href={payment.receipt_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Receipt
                              </a>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
