import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  CssBaseline,
  CircularProgress,
  Backdrop,
  createTheme,
  ThemeProvider,
  Stack,
  Alert,
} from '@mui/material';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { useLoginSuperAdminMutation } from '../../apis/superAdminApis/superAdminApis';
import { APP_FONT } from '../../constants/font';
import jonsal_logo from '../../images/jonsal_logo_transparent.png';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  palette: {
    primary: {
      main: '#4c00b0',
    },
    secondary: {
      main: '#4c00b0',
    },
  },
});

type LoginFormInputs = {
  email: string;
  password: string;
};

const Login = () => {
  const [loginSuperAdmin] = useLoginSuperAdminMutation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setIsLoading(true);
    setLoginError('');
    try {
      console.log('data successful:', data);
      const loginAdmin = await loginSuperAdmin(data).unwrap();
      console.log('Login successful:', loginAdmin);
      setIsLoading(false);
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Login failed:', error);
      setIsLoading(false);
      setLoginError(
        error.data?.message ||
          'Login failed. Please check your credentials and try again.',
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', mt: 5 }}>
        <img
          src={jonsal_logo}
          alt="Jonsal Logo"
          style={{ width: 200, height: 200 }}
        />
        <Grid container component="main" justifyContent="center">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
              borderRadius: 1,
              backgroundColor: '#fff',
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 700, color: '#4c00b0' }}
            >
              Login
            </Typography>
            {loginError && (
              <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                {loginError}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Invalid email format',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: 'Password is required' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    error={!!errors.password || !!loginError}
                    helperText={
                      errors.password?.message ||
                      (loginError && !errors.password?.message
                        ? loginError
                        : '')
                    }
                  />
                )}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: 'primary.main',
                  '&:hover': { bgcolor: 'secondary.main' },
                }}
              >
                Sign In
              </Button>
              {isLoading && (
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </Box>
          </Box>
        </Grid>
      </Stack>
    </ThemeProvider>
  );
};

export default Login;
