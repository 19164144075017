import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { HeaderDrawer } from '../../components/header/headerDrawer';
import {
  Alert,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import PendingDealersTable from '../../components/pendingDealers/PendingDealers';
import AddDealerModal from '../../components/addDealersModal/AddDealersModal';
import { SetStateAction, useState } from 'react';
import { useGetAllDealersWithStatusQuery } from '../../apis/dealershipApis/dealershipApis';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#4c00b0',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
        },
      },
    },
  },
});

export const OnboardDealers = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');
  const { data, refetch, isLoading } =
    useGetAllDealersWithStatusQuery('pending');

  const handleAlert = (message: SetStateAction<string>, type: string) => {
    if (type === 'error') {
      setErrorAlert(message);
    } else {
      setAlertMessage(message);
    }
    setTimeout(() => {
      setAlertMessage('');
      setErrorAlert('');
    }, 5000);
  };

  const handleAddDealer = () => {
    refetch();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HeaderDrawer />
      </ThemeProvider>
      <Box component="main" sx={{ flexGrow: 1, p: isMobileOrTablet ? 2 : 5 }}>
        <Toolbar />
        {alertMessage && (
          <Alert
            severity="success"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              fontSize: 16,
              top: 20,
              right: 20,
            }}
          >
            {alertMessage}
          </Alert>
        )}
        {errorAlert && (
          <Alert
            severity="error"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              fontSize: 16,
              top: 20,
              right: 20,
            }}
          >
            {errorAlert}
          </Alert>
        )}
        <Box
          sx={{
            display: isMobileOrTablet ? 'inherit' : 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              marginBottom: '2%',
              fontFamily: APP_FONT,
              fontWeight: '600',
              fontSize: isMobileOrTablet ? '30px' : '48px',
              color: '#003650',
            }}
          >
            Onboard Dealers!
          </Typography>
          <AddDealerModal
            handleAlert={handleAlert}
            handleAddDealer={handleAddDealer}
          />
        </Box>

        <PendingDealersTable dealers={data?.data || []} isLoading={isLoading} />
      </Box>
    </Box>
  );
};
