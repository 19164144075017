import { configureStore } from '@reduxjs/toolkit';
import superAdminApi from '../apis/superAdminApis/superAdminApis';
import { paymentApis } from '../apis/paymentApis/paymentApis';
import { DealersApi, signupLinksApi } from '../apis/dealershipApis/dealershipApis';
import { zendeskApi } from '../apis/zendeskApis/zendeskApi';
import { salesPersonsApis } from '../apis/salesPersonsApis/salesPersonsApis';
import { salesCommissionApis } from '../apis/salesCommissionApis/salesCommissionApis';

const store = configureStore({
  reducer: {
    [superAdminApi.reducerPath]: superAdminApi.reducer,
    [DealersApi.reducerPath]: DealersApi.reducer,
    [signupLinksApi.reducerPath]: signupLinksApi.reducer,
    [paymentApis.reducerPath]: paymentApis.reducer,
    [zendeskApi.reducerPath]: zendeskApi.reducer,
    [salesPersonsApis.reducerPath]: salesPersonsApis.reducer,
    [salesCommissionApis.reducerPath]: salesCommissionApis.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(superAdminApi.middleware)
      .concat(DealersApi.middleware)
      .concat(signupLinksApi.middleware)
      .concat(paymentApis.middleware)
      .concat(zendeskApi.middleware)
      .concat(salesPersonsApis.middleware)
      .concat(salesCommissionApis.middleware),
});

export default store;
