import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';
import { format } from 'date-fns';
const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#4c00b0',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#4c00b0',
            },
          },
        },
      },
    },
  },
});

function CustomerSupportTable({ data, isLoading }: any) {
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');
  const [view, setView] = useState('unsolved');
  const [tickets, setTickets] = useState<any>([]);
  const handleToggleChange = (event: any, newView: any) => {
    if (newView !== null) {
      setView(newView);
    }
  };
  useEffect(() => {

    if (view === "unsolved") {
      const unsolvedTickets = data?.filter((ticket: { status: string; }) => ticket.status !== 'solved');
      setTickets(unsolvedTickets)
    }
    else {
      const solvedTickets = data?.filter((ticket: { status: string; }) => ticket.status === 'solved');
      setTickets(solvedTickets);
    }
  }, [data, view]);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <CircularProgress />
      </div>
    );

  const handleRowClick = (ticketId: any) => {
    const zendeskUrl = `https://${process.env.REACT_APP_ZENDESK_SUBDOMAIN}.zendesk.com/agent/tickets/${ticketId}`;
    window.open(zendeskUrl, '_blank');
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ width: '100%', overflowY: 'hidden' }}>
        <Typography
          variant={isMobileOrTablet ? 'h6' : 'h4'}
          align="center"
          sx={{ my: 3, mx: isMobileOrTablet ? 3 : 0, fontWeight: 700 }}
        >
          Customer Support Tickets
        </Typography>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleToggleChange}
          aria-label="ticket view"
          sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
        >
          <ToggleButton value="unsolved" aria-label="unsolved tickets">
            Unsolved
          </ToggleButton>
          <ToggleButton value="solved" aria-label="solved tickets">
            Solved
          </ToggleButton>
        </ToggleButtonGroup>

        <TableContainer sx={{ margin: 'auto', maxHeight: 575 }}>
          <Table stickyHeader aria-label="customer support table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Requester Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Requester Name</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Subject</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Date/time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets?.map((ticket: any) => (
                <TableRow
                  key={ticket.id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleRowClick(ticket?.id)}
                  hover
                >
                  <TableCell>{ticket?.requesterEmail}</TableCell>
                  <TableCell>{ticket?.requesterName}</TableCell>
                  <TableCell>{ticket?.subject}</TableCell>
                  <TableCell>
                    {ticket?.created_at ? format(new Date(ticket.created_at), 'MMMM dd, yyyy h:mm a') : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </ThemeProvider>
  );
}

export default CustomerSupportTable;
