import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface SalesCommission {
  id: string;
  sales_person_name: string;
  sales_person_email?: string;
  commission: number;
  month: string;
}

export const salesCommissionApis = createApi({
  reducerPath: 'salesCommissionApis',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/sales-commission/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllSalesCommission: builder.query<SalesCommission[], void>({
      query: () => 'getAllSalesCommission',
    }),
  }),
});

export const {
  useGetAllSalesCommissionQuery,
} = salesCommissionApis;
