import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_FONT } from '../../constants/font';
import { jwtDecode } from 'jwt-decode';
import { useLogoutSuperAdminMutation } from '../../apis/superAdminApis/superAdminApis';

interface DecodedToken {
  email: string;
}

const drawerWidth = 240;

const drawerTab = {
  marginY: '6px',
  color: '#fff',
  '&:hover': {
    color: '#fff',
  },
};

export const HeaderDrawer = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [logoutSuperAdmin] = useLogoutSuperAdminMutation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [focusedItem, setFocusedItem] = useState(-1);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');
  const token = localStorage.getItem('token');
  let superAdmin: DecodedToken | null = null;
  if (token) {
    superAdmin = jwtDecode(token);
  }

  // useEffect(() => {
  //   if (currentPath === '/dashboard') {
  //     setFocusedItem(0);
  //   } else if (currentPath === '/onboard-dealers') {
  //     setFocusedItem(1);
  //   } else if (currentPath === '/customer-support') {
  //     setFocusedItem(2);
  //   } else if (currentPath === '/sales-persons') {
  //     setFocusedItem(3);
  //   } else if (currentPath === '/sales-comission') {
  //     setFocusedItem(4);
  //   } else {
  //     setFocusedItem(-1);
  //   }
  // }, [currentPath]);

  useEffect(() => {
    if (currentPath === '/dashboard') {
      setFocusedItem(0);
    } else if (currentPath === '/onboard-dealers') {
      setFocusedItem(1);
    } else if (currentPath === '/customer-support') {
      setFocusedItem(2);
    } else {
      setFocusedItem(-1);
    }
  }, [currentPath]);

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      navigate('/');
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        console.error('Logout failed: Unauthorized');
      } else if (error.response && error.response.status === 500) {
        console.error('Logout failed: Internal Server Error');
      } else {
        console.error('Logout failed:', error.message);
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <Box sx={{ marginTop: '30px' }}>
      <List>
        <Box sx={{ marginBottom: '10px' }}>
          <ListItem
            key={'Dashboard'}
            onClick={() => navigate('/dashboard')}
            disablePadding
          >
            <ListItemButton
              selected={focusedItem === 0}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#4c00b0',
                  '&:hover': {
                    backgroundColor: '#4c00b0',
                  },
                },
              }}
            >
              <ListItemText sx={drawerTab} primary={'Dashboard'} />
            </ListItemButton>
          </ListItem>
        </Box>
        <ListItem
          key={'onboard-dealers'}
          onClick={() => navigate('/onboard-dealers')}
          disablePadding
        >
          <ListItemButton
            selected={focusedItem === 1}
            sx={{
              marginBottom: '10px',
              '&.Mui-selected': {
                backgroundColor: '#4c00b0',
                '&:hover': {
                  backgroundColor: '#4c00b0',
                },
              },
            }}
          >
            <ListItemText sx={drawerTab} primary={'Onboard Dealers'} />
          </ListItemButton>
        </ListItem>

        <ListItem
          key={'customer-support'}
          onClick={() => navigate('/customer-support')}
          disablePadding
        >
          <ListItemButton
            selected={focusedItem === 2}
            sx={{
              marginBottom: '10px',
              '&.Mui-selected': {
                backgroundColor: '#4c00b0',
                '&:hover': {
                  backgroundColor: '#4c00b0',
                },
              },
            }}
          >
            <ListItemText sx={drawerTab} primary={'Customer Support'} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem
          key={'sales-persons'}
          onClick={() => navigate('/sales-persons')}
          disablePadding
        >
          <ListItemButton
            selected={focusedItem === 3}
            sx={{
              marginBottom: '10px',
              '&.Mui-selected': {
                backgroundColor: '#4c00b0',
                '&:hover': {
                  backgroundColor: '#4c00b0',
                },
              },
            }}
          >
            <ListItemText sx={drawerTab} primary={'Sales Persons'} />
          </ListItemButton>
        </ListItem>
        <ListItem
          key={'sales-comission'}
          onClick={() => navigate('/sales-comission')}
          disablePadding
        >
          <ListItemButton
            selected={focusedItem === 4}
            sx={{
              marginBottom: '10px',
              '&.Mui-selected': {
                backgroundColor: '#4c00b0',
                '&:hover': {
                  backgroundColor: '#4c00b0',
                },
              },
            }}
          >
            <ListItemText sx={drawerTab} primary={'Sales Comission'} />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#4c00b0',
        }}
      >
        <Toolbar>
          {isMobileOrTablet && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Stack
            direction={'row'}
            spacing={isMobileOrTablet ? 0 : 2}
            sx={{ mr: 'auto', alignItems: 'center' }}
          >
            <IconButton>
              <Avatar
                sx={{
                  height: isMobileOrTablet ? 20 : 40,
                  width: isMobileOrTablet ? 20 : 40,
                  color: '#4c00b0',
                  fontWeight: 700,
                  backgroundColor: '#fff',
                }}
              >
                {superAdmin?.email.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
            <Typography
              color="inherit"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                fontSize: isMobileOrTablet ? '12px' : '18px',
                color: '#fff',
              }}
            >
              {superAdmin?.email}
            </Typography>
          </Stack>

          <Button onClick={handleLogout} sx={{ ml: isMobileOrTablet ? '25px':'20px' }}>
            <Typography
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                fontSize: isMobileOrTablet ? '12px' : '18px',
                color: '#fff',
                textTransform: 'none',
              }}
            >
              Logout
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant={isMobileOrTablet ? 'temporary' : 'permanent'}
          open={isMobileOrTablet ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#B39DDB',
              marginY: '40px',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Box>
      {isLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
};
