import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface SalesPerson {
  id: string;
  name: string;
}

export const salesPersonsApis = createApi({
  reducerPath: 'salesPersonsApis',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/sales-persons/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateSalesPersonDealershipLink: builder.mutation({
      query: ({ dealershipId, salesPersonIds }) => ({
        url: `dealerships/${dealershipId}/sales-person`,
        method: 'PATCH',
        body: { salesPersonIds },
      }),
    }),
      createSalesPerson: builder.mutation({
        query: (newSalesPersonData) => ({
          url: 'create',
          method: 'POST',
          body: newSalesPersonData,
        }),
      }),
    getAllSalesPersons: builder.query<SalesPerson[], void>({
      query: () => 'getAllSalesPersons',
    }),
    removeSalesPersonFromDealership: builder.mutation({
      query: ({ dealershipId, salesPersonId }) => ({
        url: `dealerships/${dealershipId}/sales-person/${salesPersonId}`,
        method: 'DELETE',
      }),
    }),
    deleteSalesPerson: builder.mutation<void, string>({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllSalesPersonsQuery,
  useCreateSalesPersonMutation,
  useUpdateSalesPersonDealershipLinkMutation,
  useRemoveSalesPersonFromDealershipMutation,
  useDeleteSalesPersonMutation,
} = salesPersonsApis;
