import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paymentApis = createApi({
  reducerPath: 'paymentApis',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API}/super-admin/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', 'plain/text, application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPayments: builder.query<any, string>({
      query: (dealershipId: string) => `dealers/${dealershipId}/payments`,
    }),
  }),
});

export const { useGetAllPaymentsQuery } = paymentApis;
