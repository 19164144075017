import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { APP_FONT } from '../../constants/font';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

interface Dealers {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  code: string;
  isUsed: boolean;
}

interface PendingDealersTableProps {
  dealers: Dealers[];
  isLoading: boolean;
}

function PendingDealersTable({ dealers, isLoading }: PendingDealersTableProps) {
  const isMobileOrTablet = useMediaQuery('(max-width: 600px)');
  const baseUrl = process.env.REACT_APP_BASE_APP_URL;

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ width: '100%', overflowY: 'hidden' }}>
        <Typography
          variant={isMobileOrTablet ? 'h6' : 'h4'}
          align="center"
          sx={{ my: 3, mx: isMobileOrTablet ? 3 : 0, fontWeight: 700 }}
        >
          All Dealers With Pending Invites
        </Typography>
        <TableContainer sx={{ margin: 'auto', maxHeight: 575 }}>
          <Table stickyHeader aria-label="dealers table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Signup Links</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dealers.map((dealer: Dealers) => (
                <TableRow key={dealer.id}>
                  <TableCell>{dealer.email}</TableCell>
                  <TableCell>{!dealer.isUsed && 'Pending'}</TableCell>
                  <TableCell>
                    <a
                      href={`${baseUrl}/signup?email=${dealer.email}&code=${dealer.code}`}
                      target="_blank"
                    >
                      {`${baseUrl}/signup?email=${dealer.email}&code=${dealer.code}`}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </ThemeProvider>
  );
}

export default PendingDealersTable;
